
.Pipeline {
}

.Pipeline__list * {
	transition: none;
}

.Pipeline__list {
	display:  flex;
	margin-bottom:  20px;
	align-items: center;
	flex-wrap: wrap;
}

.Pipeline__list__item {
	display:  flex;
	align-items: center;
	color:  #888;
	font-weight:  bold;
	padding:  10px 10px 10px 20px;
	font-size:  14px;
	background-color: #F5F7FA;
	margin-right:  10px;
	cursor: pointer;
	position: relative;
	height:  40px;
	justify-content: flex-end;
	text-align: right;
}




.Pipeline__list__item:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.Pipeline__list__item:before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid #F5F7FA;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}


.Pipeline__list__item:hover {
	color:  #000;
}




.Pipeline__list__item--selected {
	color:  #FFF !important;
}


.Pipeline__list .Pipeline__list__item:last-child {
	margin-right:  0;
}




.Pipeline__list__item--colour-yellow {
	background-color: #F6B21F;
}

.Pipeline__list__item--colour-yellow:before {
	border-left: 10px solid #F6B21F;
}


.Pipeline__list__item--colour-orange {
	background-color: #EE7523;
}

.Pipeline__list__item--colour-orange:before {
	border-left: 10px solid #EE7523;
}



.Pipeline__list__item--colour-darkblue {
	background-color: #272E61;
}

.Pipeline__list__item--colour-darkblue:before {
	border-left: 10px solid #272E61;
}

.Pipeline__list__item--colour-blue {
	background-color: #1D71B8;
}

.Pipeline__list__item--colour-blue:before {
	border-left: 10px solid #1D71B8;
}




.Pipeline__list__item--colour-green {
	background-color: #76B82A;
}

.Pipeline__list__item--colour-green:before {
	border-left: 10px solid #76B82A;
}


.Pipeline__list__item--colour-red {
	background-color: #BC2354;
}

.Pipeline__list__item--colour-red:before {
	border-left: 10px solid #BC2354;
}


@media only screen and (max-width: 1200px) {

	.Pipeline__list__item {
		margin-bottom: 20px;
	}
}