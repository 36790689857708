

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
	margin: 0;
	padding: 0;
	line-height: 1;
	list-style-type: none;
	box-sizing: border-box;
	/*transition: all 0.2s ease; */
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: Poppins;
	color:  #585858;
	font-size: 15px;
}

a {
	text-decoration: none;
	color:  #1D428A;
}


strong {
	font-weight:  500;
}





.table {
	width:  100%;
	border-collapse: collapse;
}

.table th, .table td {
	padding:  10px;
	text-align:  left;
	line-height:  125%;
}

.table tbody tr:nth-child(odd) {
	background-color:  #F5F7FA;
}

.table tr.r td, .table td.r, .table th.r {
	text-align:  right;
}

.table .c {
	text-align: center;
}


.table-delete {
	height:  15px;
	width:  15px;
	background-image: url('table-delete.svg');
	cursor: pointer;
}