

.NavigationBar {
	background-color: #fff;
	bottom: 0;
	left: 0;
	top: 0;
	position:  fixed;
	padding:  30px;
	width:  200px;
	z-index: 999;
}

.NavigationBar__logo {
	width: 60px;
	height: 60px;
	background-image:  url('assets/btd-logo.svg');
	margin-bottom:  40px;
}

@media only screen and (max-width: 1000px) {
	
	.NavigationBar {
		border-right: 1px solid #DADADA;
	}

	.NavigationBar__logo {
		cursor: pointer;
	}
}