.SearchResults__result {
	padding:  10px;
	border-bottom:  1px solid #E8E8E8;
}

.SearchResults__result__name {
	font-size:  18px;
	font-weight:  600;
	margin-bottom:  5px;
}

.SearchResults__result__text {
	font-size:  13px;
	color:  #555;
}

.SearchResults__result__type {
	font-size:  11px;
	font-weight:  500;
	margin-bottom:  5px;
	color:  #555;
}