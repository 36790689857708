

.Sidebar {
}

.Sidebar__list__item {
	margin-bottom:  30px;
	text-align: center;
	cursor: pointer;
}

.Sidebar__list__item__link {
	display:  block;
}

.Sidebar__list__item__link__img {
	width:  30px;
	transition: all 0.2s ease;
}

.Sidebar__list__item__link__img.FormCapture {
	width:  40px;
}

.Sidebar__list__item__link__img:hover {
	transform: scale(1.3);
}


.Sidebar__usermenu {
	position:  absolute;
	top:  50px;
	right:  130px;
}

.Sidebar__usermenu__body {
	z-index:  1;
	min-height: 300px;
	border-radius: 5px;
	padding:  20px;
	width:  300px;
	background-color:  #FFF;
}

