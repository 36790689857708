.checkboxes {
    margin-bottom: 20px;
    max-width: 540px;
}

.checkboxes__title {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0 10px 0;
}

.checkboxDiv {
    display: inline-block;
    width: 180px;
}