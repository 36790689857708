.Timer {
	display:  flex;
	align-items: center;
	justify-content: space-between;
	background-color:  #F5F7FA;
	padding:  20px 30px;
}

.Timer > div {
	flex:  1;
}

.Timer__footer {
	display:  flex;
	justify-content: flex-end;
}

.Timer__description {
	font-size:  13px;
}

.Timer__job {
	font-sie:  15px;
	font-weight: 500;
	margin-top:  5px;
}

.Timer__button {
	background-color:  #6E6E6E;
	border-radius: 100px;
	width:  40px;
	height:  40px;
	margin-left:  10px;
	background-size:  13px;
	background-repeat: no-repeat;
	background-position: center center;
	transition: all 0.2s ease;
	cursor: pointer;
}

.Timer__button:hover {
	transform: scale(1.1);
}

.Timer__button--pause {
	background-image: url('pause.svg');
}

.Timer__button--stop {
	background-image: url('stop.svg');
	background-color:  #BC2354;
}

.Timer__button--start {
	background-image: url('start.svg');
	background-color:  #76B82A;
}


.Timer__clock {
	display:  flex;
	align-items: center;
	justify-content: center;
}

.Timer__clock__header {
	background-image: url('clock.svg');
	background-size:  30px;
	background-repeat: no-repeat;
	width:  30px;
	height:  30px;
	margin-right:  5px;
}


.Timer__clock__header--running {
	-webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}


@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}




.Timer__clock__body {
	font-size:  28px;
	font-weight: 600;
}

.Timer__clock__unit {
	font-size:  16px;
	opacity:  0.6;
	margin-right:  5px;
}