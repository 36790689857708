.statement__subtitle {
    margin-bottom: 10px;
}

.statement__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 400px;
}

.statement__filters__filter {
    display: inline-block;
    max-width: 300px;
}

.statement__filters__filter select {
    width: 180px;
    padding: 3px 8px;
}

.statement_noJobsMessage {
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
}
