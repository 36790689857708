
.Loading {
	display: flex;
	align-items: center;
	color: #777;
	font-weight: 500;
}

.Loading__spinner {
	background-image:  url('spinner.svg');
	width: 50px;
	height: 50px;
	background-size: cover;
	margin-right: 10px;
}