.RecurringJobItem {
	border:  1px solid #DDD;
	border-radius: 4px;
	margin-top:  30px;
}

.RecurringJobItem__name {
	font-size:  15px;
	font-weight: bold;
}

.RecurringJobItem__account {
	margin-top:  7px;
	font-size:  13px;
}


.RecurringJobItem__body {
	display:  flex;
	justify-content: space-between;
	align-items: center;
	padding:  20px;
}

.RecurringJobItem__footer {
	border-top:  1px solid #DDD;
	background-color: #F5F7FA;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display:  flex;
	justify-content: space-between;
	padding-right: 10px;
}

.RecurringJobItem__info {
	display:  flex;
	font-size:  14px;
	align-items: center;
}

.RecurringJobItem__info__name {
	padding:  10px 0px 10px 10px;
	opacity: 0.9;
}

.RecurringJobItem__info__value {
	padding:  10px;
	font-weight:  bold;
}