
.ListFilter {
	display:  flex;
}

.ListFilter__option {
	padding:  5px 10px;
	font-size:  13px;
	cursor: pointer;
	border-radius: 50px;
	margin-right:  20px;
	border:  2px solid #E7E7E7;
}

.ListFilter__option--selected {
	font-weight:  600;
	xbackground-color:  #555;
	border:  2px solid #555;
	color:  #FFF;
}


.ListFilter__option--selected.ListFilter__option--colour-orange {
	background-color:  #EE7523;
	border:  2px solid #EE7523;
}

.ListFilter__option--selected.ListFilter__option--colour-green {
	background-color:  #5DA12D;
	border:  2px solid #5DA12D;
}

.ListFilter__option--selected.ListFilter__option--colour-red {
	background-color:  #BC2354;
	border:  2px solid #BC2354;
}