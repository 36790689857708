/* Message CSS */

.Message {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;
    margin: 20px 0;
}

.Message--colour-red {
    color: #af0c40;
}

.Message--colour-green {
    color: #17c21f;
}