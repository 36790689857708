/* Form CSS */

.form {
}

.form__field {
    margin: 20px 0;
    max-width:  500px;
}

.form__field--full {
    max-width:  100%;
}

.form__field__input {
    padding: 10px;
    width: 100%;
    font-size:  inherit;
    font-family: inherit;
    border:  1px solid #C0C0C0;
}

textarea.form__field__input {
    line-height:  130%;
    margin-bottom: 20px;
}

.form__field__input--size-small {
    width:  25%;
}

.form__field__input--size-tiny {
    width:  15%;
}

.form__field--error .form__field__input {
    border:  2px solid red;
}

.form__field__label {
    display: block;
    margin: 5px 0;
}

.form__field__error {
    margin-top:  5px;
    color:  #FF0000;
}

.form__footer {
    display:  flex;
    justify-content: space-between;
    align-items: center;
}

.form__columnContainer {
    display: flex;
    justify-content: space-between;
    max-width:  500px;
}

.form__columnContainer .form__field {
    width: 45%;
}

.form__field__textEditor {
    margin: 0 0 20px -3px;
}
