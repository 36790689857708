
.InfoPanel {
	margin-bottom:  50px;
}


.InfoPanel__header {
	background-color:  #E5E9EE;
	padding:  10px;
	font-weight:  bold;
}

.InfoPanel__body {
	background-color: #F5F7FA;
	display:  flex;
	padding: 20px;
}

.InfoPanel__column {
	flex:  1;
	padding:  10px;
}

.InfoPanel__column__item {
	padding:  10px;
}

.InfoPanel__column__item__header {
	font-weight: 500;
	line-height:  130%;
	margin-bottom:  3px;
}

.InfoPanel__column__item__body {
	line-height:  130%;
}



.InfoPanel__column__item--colour-red {
	color:  #BC2354;
}

.InfoPanel__column__item--colour-green {
	color:  #2ab925;
}

.InfoPanel__column__item--colour-orange {
	color:  #EE7523;
}

.InfoPanel__body ol, 
.InfoPanel__body ul {
	padding-left: 20px;
}

.InfoPanel__body ol li {
	list-style-type: lower-roman;
}

.InfoPanel__body ul li {
	list-style-type: disc;
}

.InfoPanel__body table {
	border-collapse: collapse;
}

.InfoPanel__body th, 
.InfoPanel__body td {
	border: 1px solid #999;
}

@media only screen and (max-width: 600px) {

	.InfoPanel__body {
		display: block;
		text-align: center;
	}
}