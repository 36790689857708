
.EmptyState {
	display:  flex;
	align-items: center;
	color:  #888;
	margin:  30px 0;
}

.EmptyState__header {
	width:  50px;
	height:  50px;
	background-image: url('sad.svg');
	margin-right:  20px;
}