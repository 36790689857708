
.Navigation__list__item__link {
	display:  flex;
	font-size:  15px;
	font-weight:  500;
	color:  inherit;
	padding:  10px 0;
	transition: all 0.5s ease;
}

.Navigation__list__item__link:hover {
	transform: scale(1.1);
	transform-origin: left center;
	color:  #222;
}

.Navigation__more {
	cursor: pointer;
	opacity: 0.4;
	width:  30px;
	height:  6px;
	background-image:  url('more.svg');
	background-repeat: no-repeat;
	margin-top:  35px;
}

.Navigation__more:hover {
	opacity:  1;
}

.Navigation__other {
	margin-top:  50px;
}


.Navigation__other__heading {
	font-size:  12px;
	color: #999;
	margin-bottom: 10px;
}