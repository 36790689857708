.Reports__list__subtitle {
    margin-bottom: 10px;
}

.Reports__list__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 1000px;
}

.Reports__list__filters__filter {
    display: inline-block;
    max-width: 300px;
}

.Reports__list__filters__filter select {
    width: 180px;
    padding: 3px 8px;
}

.Reports__list__filters__filter__date.form__field__input {
    width: 180px;
    padding: 3px 8px;
}

.Reports__list__total {
    font-weight: 700;
    float: right;
    margin: 30px 30px 0 0;
}

.Reports__message-noItems {
    font-size: 18px;
    font-style: italic;
    padding-top: 40px;
    text-align: center;
}