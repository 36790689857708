.external-ticket {
	margin:  10px 10px 10px 30px;
}

.external-ticket a {
	display:  flex;
	white-space: nowrap;
	align-items: center;
	padding:  10px 15px;
	border-radius: 50px;
	background-color:  #FFF;
	font-weight:  500;
	color:  #333;
	border:  1px solid #E7E7E7;
}

.external-ticket--helpscout a img {
	width:  20px;
	margin-right:  10px;
}

.external-ticket a:hover {
	background-color: #FFF8CF;
	border:  1px solid #F4EBB7;
}