.Search__handle {
	opacity:  0.4;
	background-image:  url('glass.svg');
	width:  20px;
	height:  20px;
	background-size:  20px;
	margin-bottom: 20px;
	cursor: pointer;
}

.Search__handle:hover {
	opacity:  1;
}


.Search__window {
	position:  absolute;
	width:  400px;
	z-index:  100;
	border-radius: 5px;
	background-color:  #FFF;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
}

.Search__window__header {
	background-color:  #F5F7FA;
	padding:  20px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.Search__window__body {
	height:  400px;
	overflow:  scroll;
}