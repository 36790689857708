.Avatar {
	display:  flex;
	align-items: center;
}

.Avatar__name {
	margin-right:  10px;
}

.Avatar__image {
	display:  flex;
	align-items: center;
	justify-content: center;
	width:  60px;
	height:  60px;
	border-radius: 100px;
	background-size: 100%;
	background-color: #1D428A;
	background-position: center;
	font-size:  18px;
	font-weight:  700;
	color:  #FFF;
}

.Avatar__image--size-large {
	width:  150px;
	height:  150px;
	font-size:  50px;
}

.Avatar__image--size-small {
	width:  40px;
	height:  40px;
	font-size:  15px;
}