.JobItem {
	border:  1px solid #DDD;
	border-radius: 4px;
	margin-top:  30px;
}




.JobItem__name {
	font-size:  15px;
	font-weight: bold;
}

.JobItem__account {
	margin-top:  7px;
	font-size:  13px;
}


.JobItem__body {
	display:  flex;
	justify-content: space-between;
	align-items: center;
	padding:  20px;
}

.JobItem__footer {
	display:  flex;
	border-top:  1px solid #DDD;
	background-color: #F5F7FA;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.JobItem__info {
	display:  flex;
	font-size:  14px;
	xborder-right:  1px solid #DDD;
	align-items: center;
}

.JobItem__info-time {
	margin: 0 20px 0 auto;
}

.JobItem__info__name {
	padding:  10px 0px 10px 10px;
	opacity: 0.9;
}

.JobItem__info__value {
	padding:  10px;
	font-weight:  bold;
}