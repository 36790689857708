
.Overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position:  absolute;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999;
}

.Overlay__close {
	position:  absolute;
	top:  35px;
	right:  35px;
	cursor: pointer;
	width:  50px;
	height:  50px;
	background-color:  #FFF;
	border-radius: 100px;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 10, 1.0);
	background-image: url('close.svg');
	background-position: center center;
	background-size:  20px;
	background-repeat: no-repeat;
	transition: all 0.5s ease;
}

.Overlay__close:hover {
	transform: scale(1.3) rotate(90deg);
	transform-origin: center center;
}
