
.Button {
	display:  inline-block;
	color:  #FFF;
	padding:  10px 15px;
	text-align: center;
	border:  0;
	outline:  0;
	font-weight:  600;
	margin-right:  10px;
	cursor: pointer;
	border-radius: 50px;
	min-width:  100px;
	font-family: inherit;
	font-size:  14px;
}

.Button:hover {
	opacity:  0.8;
}

.Button--size-small, .Button--type-delete {
	padding:  8px 10px;
	font-size:  12px;
	font-weight:  bold;
}


.Button--type-delete {
	opacity:  0.5;
	margin-top:  120px;
}



/* Colour Variants
-----------------------------------------------*/

.Button--colour-blue, .Button--colour-default {
	background-color:  #1D428A;
}

.Button--colour-orange {
	background-color:  #EE7523;
}

.Button--colour-green {
	background-color:  #5DA12D;
}

.Button--colour-red, .Button--type-delete {
	background-color:  #BC2354;
}