
.ImageUpload {
	position:  relative;
	width:  200px;
	height:  100px;
	background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
}


.ImageUpload__UploadHandle {
	display:  none;
	position: absolute;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	background-color: rgba(0, 0, 0, 0.5);
	background-image: url('upload.svg');
	background-size:  50px;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}

.ImageUpload:hover .ImageUpload__UploadHandle{
	display: block;
}


.FileUpload {
	position:  fixed;
	display:  flex;
	align-items: center;
	justify-content: center;
	width:  500px;
	height:  500px;
	box-shadow: 0px 0px 50px rgba(0, 0, 50, 1);
	background-color:  #FFF;
	border-radius: 5px;
}


.dropzone {
	display: flex;
	align-items: center;
	justify-content: center;
	padding:  30px;
	flex:  1;
	text-align: center;
	color:  #888;
	font-size:  20px;
	height:  500px;
	background-image:  url('icon.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size:  100px;
}

.FileUpload__text {
	font-size:  20px;
	margin-top:  250px;
}


.FileUpload--active {
	transform: scale(1.1);
}

.ImageUpload__images {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
}

.ImageUpload__images__image {
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	height: 400px;
	margin: 30px 30px 30px 0;
	position: relative;
	max-width: 400px;
	width: 100%;
}

.ImageUpload__images__image__close {
	background-image: url('assets/table-delete.svg');
	cursor: pointer;
	display: none;
	height:  15px;
	position: absolute;
	right: 20px;
	top: 20px;
	width:  15px;
}

.ImageUpload__images__image__close:hover {
	transform: scale(1.25);
}

.ImageUpload__images__image:hover .ImageUpload__images__image__close {
	display: block;
}

.ImageUpload__images__largeImage {
	align-items: center;
	background-color: #fff;
	cursor: auto;
	display: none;
	height: max-content;
	left: 17.5vw;
	margin: auto auto;
	position: fixed;
	top: 5vh;
	width: max-content;
	z-index: 99999;
}

.ImageUpload__images__largeImage.active {
	display: flex;
}


.ImageUpload__images__largeImage__close {
	background-color:  #FFF;
	background-image: url('close.svg');
	background-position: center center;
	border-radius: 100px;
	background-repeat: no-repeat;
	background-size:  20px;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 10, 1.0);
	cursor: pointer;
	height:  50px;
	position:  absolute;
	right:  35px;
	top:  35px;
	transition: all 0.5s ease;
	width:  50px;
}

.ImageUpload__images__largeImage__close:hover {
	transform: scale(1.3) rotate(90deg);
	transform-origin: center center;
}

.ImageUpload__images__largeImage__image {
	background-repeat: no-repeat;
	background-size: contain;
	height: auto;
	margin: 0 auto;
	width: 65vw;
}

#ImageUpload__overlay {
	background-color: rgba(0,0,0,0.7);
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
}

#ImageUpload__overlay.active {
	display: block;
}

@media only screen and (max-width: 1000px) {

	.ImageUpload__images__largeImage {
		left: 7.5vw;
	}
	
	.ImageUpload__images__largeImage__image {
		width: 85vw;
	}
}

@media only screen and (max-width: 750px) {

	.ImageUpload__images__largeImage__close {
		height:  15px;
		width:  15px;
	}
}


@media only screen and (max-width: 550px) {

	.ImageUpload__images__largeImage {
		left: 2.5vw;
	}
	
	.ImageUpload__images__largeImage__image {
		width: 95vw;
	}
}
