.DefaultLayout {
	position:  fixed;
	top:  0;
	bottom:  0;
	left:  0;
	right:  0;
	display:  flex;
}

.DefaultLayout__header-mobile {
	display: none;
}

.DefaultLayout__header-mobile__logo {
	background-image:  url('assets/btd-logo.svg');
	cursor: pointer;
	height: 60px;
	margin: 20px 20px 30px 20px;
	width: 60px;
}

.DefaultLayout__header {
	display: block;
}

.DefaultLayout__body {
	flex:  1;
	margin-left: 200px;
	border-left:  1px solid #DADADA;
	border-right:  1px solid #DADADA;
}

.DefaultLayout__body__main {
	overflow: scroll;
	height:  100%;
	margin-top:  10px;
	padding:  30px;
}

.DefaultLayout__footer {
	padding:  30px;
}

@media only screen and (max-width: 1000px) {

	.DefaultLayout {
		display: block;
	}
	
	.DefaultLayout__header {
		display: none;
	}

	.DefaultLayout__header.active {
		background-color: #fff;
		display: block;
		z-index: 99;
	}

	.DefaultLayout__header-mobile {
		display: block;
	}

	.DefaultLayout__body {
		display: block;
		margin-left: 0;
		border-left: none;
		border-right: none;
	}

	.DefaultLayout__body__main {
		padding: 20px;
	}

	#menu__overlay {
		display: none;
	}

	#menu__overlay.active {
		background-color: transparent;
		bottom: 0;
		display: block;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 9;
	}

	.DefaultLayout__footer {
		display: none;
	}
}

@media only screen and (max-width: 1000px) {

	.DefaultLayout {
		position:  relative;
	}
}