
.ScreenTitle {
	margin-bottom:  50px;
}

.ScreenTitle__header {
	margin-bottom:  20px;
}

.ScreenTitle__header__title {
	font-size:  24px;
	font-weight:  400;
}

@media only screen and (min-width: 750px) {

	.ScreenTitle {
		display:  flex;
		align-items: center;
		justify-content: space-between;
	}

	.ScreenTitle__header {
		margin-bottom:  0;
	}

}
