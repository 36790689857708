.Tabs {

}

.Tabs__list {
	display:  flex;
	border-bottom:  1px solid #DDD;
	height:  40px;
}

.Tabs__list__item {
	padding:  10px 5px;
	cursor: pointer;
	margin-right:  30px;
}

.Tabs__panel {
	margin-top:  20px;
}

.Tabs__list__item--selected {
	border-bottom:  3px solid #1D428A;
	height:  41px;
	font-weight:  500;
}

.Tabs__list__item__count {
	font-weight:  bold;
	font-size:  11px;
	margin-left:  5px;
	opacity:  0.75;
}