/* Documentation View Screen CSS */

.documentation {
    display: flex;
    padding: 20px 0 60px 0;
}

.documentation__updated {
    font-style: italic;
    opacity: 0.7;
}

.documentation__page {
    padding-right: 20px;
    width: 70%;
}

.documentation__page__title {
    margin: 30px 0;
}

.documentation__page__buttons {
    margin: 40px 0 0 0;
}

.documentation__page__body {
    margin-bottom: 40px;
}

.documentation__page__body h1, 
.documentation__page__body h2, 
.documentation__page__body h3 {
    margin: 40px 0;
}

.documentation__page__body p {
    line-height: 1.4;
}

.documentation__page__body ul {
    margin: 0 0 20px 20px;
}

.documentation__page__body ul li {
    line-height: 1.4;
    list-style-type: disc;
}

.documentation__page__body hr {
    margin-bottom: 10px;
}

.documentation__page__label {
    color: #1D428A;
    display: block;
    font-weight: 700;
    transform: translate(50px, 50px);
}

.documentation__links {
    border-top: 1px solid #DADADA;
    padding: 20px 0;
}

.documentation__links__title {
    margin-bottom: 20px;
}


@media only screen and (max-width: 750px) {
    
    .documentation__page {
        width: 100%;
    }
}