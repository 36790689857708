/* Documentation Menu CSS */

.DocumentationMenu {
	background-color: #F5F7FA;
	border: 1px solid #ddd;
	padding: 30px 0;
	max-width: 430px;
	width: 30%;
}

.DocumentationMenu__title {
	margin-bottom: 30px;
	text-align: center;
}

.DocumentationMenu__list {
	margin-left: 20px;
}

.DocumentationMenu__list__item {
	color: #1D428A;
	cursor: pointer;
	display: block;
	font-weight: 400;
	margin-top: 20px;
}

.DocumentationMenu__list__item-topLevel {
	font-weight: 700;
	margin-bottom: 30px;
}

.DocumentationMenu__list__item-child, 
.DocumentationMenu__list__item-grandchild {
	/* display: block;
	font-weight: 400;
	margin-top: 20px; */
}

.DocumentationMenu__list__item-grandchild {
	
}

@media only screen and (max-width: 750px) {

	.DocumentationMenu {
		height: 80px;
		overflow: hidden;
		right: 20px;
		position: absolute;
		width: 120px;
	}

	.DocumentationMenu__title {
		cursor: pointer;
	}

	.DocumentationMenu.active {
		height: 100%;
		max-width: 350px;
		width: 100%;
	}
}