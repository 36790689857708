.Times__list__subtitle {
    margin-bottom: 10px;
}

.Times__list__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 1000px;
}

.Times__list__filters__filter {
    display: inline-block;
    max-width: 300px;
}

.Times__list__filters__filter select {
    width: 180px;
    padding: 3px 8px;
}

.Times__list__filters__filter__date.form__field__input {
    width: 180px;
    padding: 3px 8px;
}

.Times__list__total {
    font-weight: 700;
    float: right;
    margin-top: 30px;
}