/* Contract View CSS */
/* 
.recharts-responsive-container {
    height: 400px !important;
} */

.usage {
    padding-top: 20px;
}

.usage__filters {
    display: flex;
    margin-bottom: 30px;
}

.usage__filters__filter {
    margin-right: 30px;
    max-width: 200px;
}

.usage .recharts-responsive-container {
    height: 600px !important;
}

.annualUsage__filter {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    padding: 10px;
}