/* .demo-editor {
    border:  1px solid #CCC;
    min-height:  500px;
    padding:  10px;
    margin-bottom: 20px;
    max-height:  500px;
} */

.ck.ck-editor {
    margin: 20px 0;
}

.ck-content {
    height: 500px;
}

.ck.ck-content.ck-editor__editable_inline {
    padding: 10px 30px;
    line-height: 1.5;
}

.ck.ck-editor ol li {
    list-style-type: lower-roman;
}

.ck.ck-editor ul li {
    list-style-type: disc;
}