

.Breadcrumb {
	font-size:  12px;
	margin-bottom:  5px;
	margin-bottom:  10px;
}

.Breadcrumb__list {
	display:  flex;
}

.Breadcrumb__list__item {
	display:  flex;
	align-items: center;
	margin-right:  10px;
	color:  #999;
}

.Breadcrumb__list__item__link {
	display:  block;
	margin-right:  5px;
	font-weight:  500;
}

.Breadcrumb__list__item:after {
	content:  ' > ';
}

.Breadcrumb__list__item:last-child:after {
	content:  '';
}